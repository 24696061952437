import React, { useState } from "react";
import MapContext, { initialState } from "./MapContext";

const MapProvider = props => {
	//loading Mask
	const [openMenu, setOpenMenu] = useState(false)
	const [loadingMask, setLoadingMask] = useState(false);
	const [navbeaguide, setNavBeaGuide] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [zoom, setZoom] = useState("");
	const [mapRef, setMapRef] = useState("");
	// Guide list and Map context
	const [location, setLocation] = useState(initialState.location);
	const [isLocationDisable, setLocationDisable] = useState("");
	const [guideImages, setGuideImages] = useState([]);
	const [guides, setGuides] = useState([]);
	const [guidesWithinRange, setGuidesWithinRange] = useState([]);
	const [allGuides, setAllGuides] = useState([]);
	const [allMarker, setAllMarker] = useState([]);
	const [hoverCardId, setHoverCardId] = useState(null);
	const [tapCard, setTapCard] = useState(null);
	const [clearSearch, setClearSearch] = useState(false);
	const [hoverPoiId, setHoverPoiId] = useState(0);
	const [markerOnHover, setMarkerOnHover] = useState(0);
	const [poimarkerOnHover, setPoiMarkerOnHover] = useState(0);
	const [markerOnClick, setMarkerOnClick] = useState(0);
	const [selectedGuide, setSelectedGuide] = useState(null);
	const [loadGuide, setLoadGuide] = useState(false);
	const [timeCard, setTimeCard] = useState(null);
	const [guideRates, setGuideRates] = useState([]);
	const [guideRateCard, setGuideRateCard] = useState([]);
	const [loadcount, setLoadCount] = useState(0);
	const [rateCardCount, setRateCardCount] = useState(0);
	//login and registration context
	const [modalShow, setModalShow] = useState(false);
	const [signupShow, setSignupShow] = useState(false);
	const [loginShow, setLoginShow] = useState(false);
	const [loginStatus, setLoginStatus] = useState(false);
	const [radioButton, setRadioButton] = useState(2);
	const [hideRadioButton, setHideRadioButton] = useState(null);
	//filterguides context
	const [searchedValue, setSearchedValue] = useState("");
	const [datefromHome, setDateFromHome] = useState(null);
	const [searchedCity, setSearchedCity] = useState("");
	const [searchedState, setSearchedState] = useState("");
	const [adjustMonth, setAdjustMonth] = useState('');
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [count, setCount] = useState(1);
	const [adultCount, setAdultCount] = useState(0);
	const [childCount, setChildCount] = useState(0);
	const [showCountError, setShowCountError] = useState(false);
	const [availDate, setAvailDate] = useState();
	const [filtersearchselectedTime, setFilterSearchSelectedTime] = useState(
		[]
	);
	const [selectedTime, setSelectedTime] = useState([]);
	const [selectedRates, setSelectedRates] = useState({});
	const [selectedRateCardTitle, setSelectedRateCardTitle] = useState(null);
	const [selectedRateCard, setSelectedRateCard] = useState(null);
	const [checkedDate, setCheckedDate] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedTripType, setSelectedTripType] = useState("");
	const [searchSelectedTripType, setSearchSelectedTripType] = useState([]);
	const [lcount, setLCount] = useState(0);
	const [selectedFishingType, setSelectedFishingType] = useState([]);
	const [triplength, setTripLength] = useState([]);
	const [fishingType, setFishingType] = useState(1);
	const [disabledFishingType, setdisabledFishingType] = useState(false);
	const [inputFocus, setInputFocus] = useState(false);

	//Tabs context
	const [guideTab, setGuideTab] = useState(false);
	const [personalTab, setPersonalTab] = useState(false);
	const [paymentTab, setPaymentTab] = useState(false);
	const [confirmationTab, setConfirmationTab] = useState(false);

	// guide details
	const [selectedRiver1, setSelectedRiver1] = useState([]);
	const [selectedRiver2, setSelectedRiver2] = useState([]);
	const [meal, setMeal] = useState([]);
	const [meetingPoint, setMeetingPoint] = useState([]);
	const [notes, setNotes] = useState("");
	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [showPersonalInfo, setShowPersonalInfo] = useState(false);
	const [showPaymentInfo, setShowPaymentInfo] = useState(false);
	const [extraPerson, setExtraPerson] = useState(0);

	// personal info context
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [requireFn, setRequiredFn] = useState(false);
	const [requireLn, setRequiredLn] = useState(false);
	const [requireEmail, setRequiredEmail] = useState(false);
	const [requirePhone, setRequiredPhone] = useState(false);
	const [formattedPhone, setFormattedPhone] = useState("");

	// confirm booking context
	const [tripguide, setTripguid] = useState(null);
	const [bookingDetails, setBookingDetails] = useState(null);

	//location
	const [markers, setMarkers] = useState([]);
	const [path, setPath] = useState([]);
	const [path1, setPath1] = useState([]);
	const [pathCopy, setPathCopy] = useState([]);
	const [displayPath, setDisplayPath] = useState(true);
	const [show, setShow] = useState(false);

	const [coordinates, setCoordinates] = useState("");
	const [enable, setEnable] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [popup, setPopup] = useState(false);
	const [schrecords, setSchRecords] = useState([]);
	const [notAvail, setNotAvail] = useState([]);
	const [schedule, setSchedule] = useState([]);
	const [scheduleDay, setScheduleDay] = useState([]);
	const [showInput, setShowInput] = useState(false);

	const [disableWade, setDisableWade] = useState(true);
	const [disableBoat, setDisableBoat] = useState(true);
	const [toFitBoundCity, setToFitBoundCity] = useState("");
	const [buttonClass, setButtonClass] = useState("");
	const [guestFromAdsPage, setGuestFromAdsPage] = useState(0);

	const [tip, setTip] = useState("");
	const [tipType, setTipType] = useState("");
	const [guideReviews, setGuideReviews] = useState([]);
	const [searchPagination, setSearchPagination] = useState({
		limit: 20,
		active: 1
	});

	const [isPageChanged, setPageChanged] = useState(false);
	const [nearByGuidesCount, setNearByGuidesCount] = useState(0);
	const [guidePins, setGuidePins] = useState([]);
	const [mapBounds, setMapBounds] = useState(null);
	const [mapDrag, setMapDrag] = useState(false);
	const [isMarlin, setIsMarlin] = useState(false)
	const [posChargeToday, setPosChargeToday] = useState();
	const [posChargeOnDayOfTrip, setPosChargeOnDayOfTrip] = useState();
	const [posTotalTripCost, setPosTotalTripCost] = useState();
	const [guidesAPICalled, setGuidesAPICalled] = useState(true);
	const [depositPercentage, setDepositPercentage] = useState(20);
	const [posRef, setPosRef] = useState()
	const [territorytitle, setTerritoryTitle] = useState("")
	const [waterbodiesHide, setWaterBodiesHide] = useState(false);
	const [territoryTitleUp, setTerritoryTitleUp] = useState('');

	const [paymentCardDetails, setPaymentCardDetails] = useState({})
	const [promo, setPromo] = useState({
		"show": false,
		"promoCode": "",
		"error": "",
		"applied": false,
		"response": "",
	})
	const [resetClick, setResetClick] = useState(false);
	const [showPicker, setShowPicker] = useState(false);
	const [paymentPOSDetails, setPaymentPOSDetails] = useState({})
	const [firstAvailableDate, setFirstAvailableDate] = useState(null)
	const [searchMetaData, setSearchMetaData] = useState(null)
	const [promoCode, setPromoCode] = useState('NEWGUIDELISTING2023')
	const [selectedMeetingPoint, setSelectedMeetingPoint] = useState(null);
	const [resetData, setResetData] = useState(false);
	const [resetTerritory, setResetTerritory] = useState(false);
	const [selectGuideType, setSelectGuideType] = useState('a Guide')
	const [guidePic, setGuidePic] = useState('')
	const [bsGVAvailability, setBSGVAvailability] = useState(null)

	return (
		<MapContext.Provider
			value={{
				promoCode, setPromoCode,
				territorytitle, setTerritoryTitle,
				openMenu, setOpenMenu,
				clearSearch, setClearSearch,
				tapCard, setTapCard,
				searchPagination,
				setSearchPagination,
				loadcount,
				setLoadCount,
				navbeaguide,
				setNavBeaGuide,
				scheduleDay,
				setScheduleDay,
				schrecords,
				setSchRecords,
				showInput,
				setShowInput,
				zoom,
				setZoom,
				mapRef,
				setMapRef,
				notAvail,
				setNotAvail,
				schedule,
				setSchedule,
				submit,
				setSubmit,
				selectedRates,
				setSelectedRates,
				loadingMask,
				setLoadingMask,
				guideImages,
				setGuideImages,
				location,
				setLocation,
				isLocationDisable,
				setLocationDisable,
				guides,
				setGuides,
				guidesWithinRange,
				setGuidesWithinRange,
				allMarker,
				setAllMarker,
				hoverCardId,
				setHoverCardId,
				hoverPoiId,
				setHoverPoiId,
				markerOnHover,
				setMarkerOnHover,
				poimarkerOnHover,
				setPoiMarkerOnHover,
				markerOnClick,
				setMarkerOnClick,
				modalShow,
				setModalShow,
				signupShow,
				setSignupShow,
				loginShow,
				setLoginShow,
				loginStatus,
				setLoginStatus,
				radioButton,
				setRadioButton,
				hideRadioButton,
				setHideRadioButton,
				searchedValue,
				setSearchedValue,
				datefromHome,
				setDateFromHome,
				searchedCity,
				setSearchedCity,
				searchedState,
				setSearchedState,
				city,
				setCity,
				state,
				setState,
				count,
				setCount,
				adultCount,
				setAdultCount,
				childCount,
				setChildCount,
				showCountError,
				setShowCountError,
				triplength,
				setTripLength,
				filtersearchselectedTime,
				setFilterSearchSelectedTime,
				selectedTime,
				setSelectedTime,
				checkedDate,
				setCheckedDate,
				startDate,
				setStartDate,
				endDate,
				setEndDate,
				selectedTripType,
				setSelectedTripType,
				selectedFishingType,
				setSelectedFishingType,
				fishingType,
				setFishingType,
				coordinates,
				setCoordinates,
				enable,
				setEnable,
				selectedGuide,
				setSelectedGuide,
				loadGuide,
				setLoadGuide,
				selectedRiver1,
				setSelectedRiver1,
				selectedRiver2,
				setSelectedRiver2,
				meal,
				setMeal,
				meetingPoint,
				setMeetingPoint,
				notes,
				setNotes,
				checkbox1,
				setCheckbox1,
				checkbox2,
				setCheckbox2,
				showPersonalInfo,
				setShowPersonalInfo,
				firstname,
				setFirstName,
				lastname,
				setLastName,
				email,
				setEmail,
				phone,
				setPhone,
				requireFn,
				setRequiredFn,
				requireLn,
				setRequiredLn,
				requireEmail,
				setRequiredEmail,
				requirePhone,
				setRequiredPhone,
				formattedPhone,
				setFormattedPhone,
				showPaymentInfo,
				setShowPaymentInfo,
				searchSelectedTripType,
				setSearchSelectedTripType,
				tripguide,
				setTripguid,
				bookingDetails,
				setBookingDetails,
				guideTab,
				setGuideTab,
				personalTab,
				setPersonalTab,
				paymentTab,
				setPaymentTab,
				confirmationTab,
				setConfirmationTab,
				displayPath,
				setDisplayPath,
				path,
				setPath,
				path1,
				setPath1,
				pathCopy,
				setPathCopy,
				markers,
				setMarkers,
				show,
				setShow,
				timeCard,
				setTimeCard,
				guideRates,
				setGuideRates,
				guideRateCard,
				setGuideRateCard,
				lcount,
				setLCount,
				forgotPassword,
				setForgotPassword,
				popup,
				setPopup,
				disabledFishingType,
				setdisabledFishingType,
				extraPerson,
				setExtraPerson,
				disableWade,
				setDisableWade,
				disableBoat,
				setDisableBoat,
				toFitBoundCity,
				setToFitBoundCity,
				inputFocus,
				setInputFocus,
				buttonClass,
				setButtonClass,
				guestFromAdsPage,
				setGuestFromAdsPage,
				tip,
				setTip,
				tipType,
				setTipType,
				guideReviews,
				setGuideReviews,
				nearByGuidesCount,
				setNearByGuidesCount,
				guidePins,
				setGuidePins,
				mapBounds,
				setMapBounds,
				mapDrag,
				setMapDrag,
				posChargeToday,
				setPosChargeToday,
				posChargeOnDayOfTrip,
				setPosChargeOnDayOfTrip,
				posTotalTripCost,
				setPosTotalTripCost,
				guidesAPICalled,
				setGuidesAPICalled,
				selectedRateCardTitle,
				setSelectedRateCardTitle,
				depositPercentage,
				setDepositPercentage,
				posRef, setPosRef,
				paymentCardDetails, setPaymentCardDetails,
				promo, setPromo,
				paymentPOSDetails,
				setPaymentPOSDetails,
				isPageChanged, setPageChanged,
				availDate, setAvailDate,
				rateCardCount, setRateCardCount,
				resetClick, setResetClick,
				selectedRateCard, setSelectedRateCard,
				showPicker, setShowPicker,
				allGuides, setAllGuides,
				firstAvailableDate, setFirstAvailableDate,
				searchMetaData, setSearchMetaData,
				selectedMeetingPoint, setSelectedMeetingPoint,
				resetData, setResetData,
				territoryTitleUp, setTerritoryTitleUp,
				waterbodiesHide, setWaterBodiesHide,
				resetTerritory, setResetTerritory,
				adjustMonth, setAdjustMonth,
				selectGuideType, setSelectGuideType, isMarlin, setIsMarlin,
				guidePic, setGuidePic,
				bsGVAvailability, setBSGVAvailability
			}}
		>
			{props.children}
		</MapContext.Provider>
	);
};

export default MapProvider;
